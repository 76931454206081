import React from 'react';
import Layout from '../components/layout';
import Container from '../components/Container';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import { Link } from 'gatsby';
export default ()=>(
  <Layout>
    <PageHeader>
      <h2 className="text-6xl">Aviso de Privacidad</h2>
      <p className="mt-2 text-sm">
        <Link to="/" className="underline">Inicio</Link>
        <i className="fas fa-chevron-right mx-2"></i>
        <Link to="/aviso-privacidad" className="underline">Aviso de Privacidad</Link>
      </p>
      {/* <p className="my-4 max-w-xl mx-auto opacity-75 text-sm">{category.description}</p> */}
    </PageHeader>
    <Container>
      <div className="my-6 text-content">
        <p>RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES DIAGNOSUR DIAGNOSTICOS S.A. de C.V., con domicilio legal en la 6ª. Poniente norte no.217, Col. Centro C .P. 29000 Tuxtla Gutiérrez, Chiapas, México, declara:Ser una sociedad Anónima, de Capital Variable, legalmente constituida, bajo las leyes de la República Mexicana, mediante Escritura Pública número 13,082, de fecha 05 de Mayo de 2009, pasada ante la Fe del Notario Público número 73 del Estado de Chiapas, Licenciado Raymundo Moreno Ballinas, subordinada a la cláusula única de exclusión de extranjeros, con permiso número 4402293 de la Secretaría de Relaciones Exteriores, registrada ante el Sistema de Administración Tributaria con el folio A7694931 y fecha de inicio de operaciones el 05 de mayo de 2009.----------- es responsable del tratamiento de sus datos personales conforme a este Aviso de Privacidad.--------------------------------------------------------------------------------------------------------------------------------------------------</p>
        <p>DATOS QUE SE RECABAN<br />Los datos personales de contacto y/o de identificación que tratará Laboratorio de análisis clínicos Diagnosur Diagnósticos son: nombre, edad, sexo, ocupación, domicilio, correo electrónico, teléfono (móvil y/o fijo) y registro federal de contribuyentes.</p>
        <p>DATOS SENSIBLES<br /> Laboratorio de análisis clínicos Diagnosur Diagnósticos recabará y tratará datos sensibles, relacionados con el estado de salud, antecedentes e historial clínico e información sobre el modo de vida, necesario o conveniente para las finalidades arriba señalados. Los datos personales sensibles serán mantenidos y tratados con estricta seguridad y confidencialidad para fines relacionados con la prestación de servicios de salud y conforme a este Aviso de Privacidad.</p>
        <div>
          FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES<br />

          <p>Los Datos Personales en posesión de Laboratorio de análisis clínicos Diagnosur Diagnósticos serán utilizados para las siguientes finalidades:</p>
        
          <p>a) Finalidades necesarias para el mantenimiento de la relación con el titular de los datos:</p>
          <ul>
            <li>Prestación de análisis de laboratorio, radiología e imagen, estudios y análisis de patología y servicios médicos.</li>
            <li>Creación, estudio, análisis, actualización y mantenimiento de archivo clínico.</li>
            <li>Facturación y cobranza por servicios.</li>
            <li>Mantenimiento de registros para seguimiento de  servicios</li>
          </ul>

          <p>b) Finalidades distintas:</p>
          <ul>
            <li>Estudios, registros, estadísticas y análisis de información de salud.</li>          
            <li>Mantenimiento de registros para prestación de servicios en el futuro. Análisis estadísticos y de mercado.</li>
            <li>Promoción y mercadeo de productos y servicios de Laboratorio de análisis clínicos Diagnosur Diagnósticos u otras empresas pertenecientes al mismo grupo corporativo.</li>
          </ul>
        </div>
        <p>
          TRANSFERENCIA<br />
          Para la prestación de análisis de laboratorio, radiología e imagen, estudios y análisis de patología, servicios médicos, creación, estudio, análisis, actualización y mantenimiento de archivos clínicos, Laboratorio de análisis clínicos Diagnosur Diagnósticos puede transferir dentro y fuera del país, los datos personales en su posesión a terceros subcontratados para los fines señalados en este Aviso de Privacidad. Los terceros a los que se transferirán dichos datos son laboratorios, clínicas, hospitales, centros de investigación, aseguradoras, así como a cualquier empresa del mismo grupo corporativo, o en su caso autoridades competentes.<br />
          No será necesario el consentimiento de los titulares cuando las transferencias se realicen a sociedades del mismo grupo de Laboratorio de análisis clínicos Diagnosur Diagnósticos  o cuando dicha transferencia sea necesaria para prestarle un servicio o mantener actualizados los archivos clínicos.<br />
        </p>
        <p>
          LIMITACIÓN DE USO Y DIVULGACIÓN DE DATOS PERSONALES <br/> Los titulares podrán limitar el uso de sus datos personales, a través de un correo electrónico a diagnosur_@hotmail.com o notificación por escrito a la 6ª. Poniente norte no.217,  Col. Centro C .P. 29000 Tuxtla Gutiérrez, Chiapas, México, dirigida a la Dirección General, de Lunes a Viernes de 9:00 a 14 hrs., en la que se señale la limitación al uso de los datos deseada.
        </p>
        <p>
          SOLICITUD DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN DE DATOS PERSONALES Y REVOCACIÓN DEL CONSENTIMIENTO (SOLICITUD ARCO)<br />Todos los datos personales son tratados de conformidad con la legislación aplicable y vigente, por ello los titulares de datos personales tienen en todo momento el derecho a acceder a los datos personales que posee Laboratorio de análisis clínicos Diagnosur Diagnósticos y a los detalles del tratamiento de los mismos, así como a rectificarlos en caso de ser inexactos o incompletos; cancelarlos cuando resulten ser excesivos o innecesarios para las finalidades que justificaron su obtención; y oponerse a su tratamiento.
        </p>
        <p>
          MEDIOS PARA EJERCER DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN (ARCO)<br/>

          Para dar inicio a una Solicitud ARCO, el titular de los datos personales deberá presentar una solicitud por escrito dirigida a la Dirección General en diagnosur_@hotmail.com o notificación por escrito a la 6ª. Poniente norte no.217,  Col. Centro C .P. 29000 Tuxtla Gutiérrez, Chiapas, dirigida a la Dirección General, de lunes a Viernes de 9:00 a 14 hrs. que contenga la siguiente información:<br />

          Nombre del titular, domicilio del titular o dirección de correo electrónico para comunicar respuesta a la solicitud, con los documentos que acrediten identidad o autorización para representarlo en la solicitud, descripción de datos personales sobre los que se pretende ejercer algún derecho ARCO, la Fecha de atención en la cual recibió los servicios y cualquier otro elemento que permita la localización de los datos personales y atención a la solicitud.<br />
        </p>
        <p>
          MEDIDAS PARA PROTEGER LOS DATOS PERSONALES<br/>

          Para prevenir el acceso no autorizado a los datos personales y con el fin de asegurar que la información sea utilizada para los fines establecidos en este Aviso de Privacidad, Laboratorio de análisis clínicos Diagnosur  Diagnósticos ha establecido procedimientos físicos, electrónicos y administrativos para evitar el uso o la revelación de los datos personales, permitiéndole a Laboratorio de análisis clínicos Diagnosur Diagnósticos tratarlos de forma adecuada. Estos procedimientos son evaluados y revisados constantemente por la Dirección General, buscando controlar el uso y divulgación de los datos.
        </p>
        <p>
          INFORMACIÓN OBTENIDA POR MEDIOS REMOTOS O LOCALES DE COMUNICACIÓN ELECTRÓNICA U ÓPTICA <br/>

          Laboratorio de análisis clínicos Diagnosur Diagnósticos recaba y almacena información a través de ingreso a su sitio Web, información sobre la dirección IP/nombre de dominio del visitante, sitios Web que hagan referencia a su sitio, comportamiento y el tiempo de estadía en el sitio Web, las páginas navegadas, herramientas utilizadas, código postal, tipo de navegador y sistema operativo entre otros.<br />

          Dicha información se obtiene y almacena con el fin de medir la actividad del sitio e identificar tendencias sobre navegación que no son atribuibles a un individuo en específico.<br />

          La información anteriormente señalada se recopila a través de las “cookies”, así como otros medios y mecanismos tecnológicos, tales como las etiquetas de pixel, los web bugs, enlaces en correos electrónicos, web beacons (etiquetas de Internet, etiquetas de pixel y clear GIFs) entre otros.<br />

          La mayoría de los exploradores le permitirán eliminar el uso de cookies, bloquear la aceptación de las cookies o recibir una advertencia antes de almacenar cookies. Le sugerimos consultar las instrucciones de su explorador para utilizar las “cookies”.<br />
        </p>
        <p>
          NOTIFICACIÓN DE CAMBIOS AL AVISO DE PRIVACIDAD<br/>

          El presente Aviso de Privacidad podrá ser modificado en cualquier momento para cumplir con actualizaciones legislativas, jurisprudenciales, políticas internas, nuevos requisitos para la prestación de servicios de Laboratorio de análisis clínicos Diagnosur Diagnósticos o cualquier otra causa a entera discreción de Laboratorio de análisis clínicos Diagnosur Diagnósticos. En tal caso, las modificaciones estarán disponibles en nuestra página de Internet http://www.diagnosur.com o cualquier otro medio de comunicación verbal, impreso o electrónico que Laboratorio de análisis clínicos Diagnosur Diagnosticos determine para tal efecto.
        </p>
        <p>
          Declaro bajo protesta de decir verdad que he leído en su totalidad este Aviso de Privacidad y entiendo plenamente su alcance y contenido. Por medio del presente otorgo mi consentimiento para que Laboratorio de análisis clínicos Diagnosur Diagnósticos trate mis datos personales de acuerdo a este Aviso de Privacidad. De igual forma expresamente consiento el tratamiento por Laboratorio de análisis clínicos Diagnosur Diagnósticos de mis datos personales sensibles y la transferencia de mis datos personales. Asimismo, consiento expresamente el tratamiento de mi información (datos personales) para las “Finalidades Distintas”, descritas en el inciso b) de la sección “FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES”.
        </p>
      </div>
      
    </Container>
    <Footer></Footer>
  </Layout>
)